import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { SanityPage, SanityPageProps } from '../components/SanityPage'

type SanityPageTemplateProps = SanityPageProps

export const query = graphql`
  query PageTemplate($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      description
      keywords
      maxWidth
      _rawOpenGraphImage(resolveReferences: { maxDepth: 5 })
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
    ...allSanityTerms
    ...comingSoonImage
  }
`

const SanityPageTemplate: FC<SanityPageTemplateProps> = ({ data, pageContext }) => {
  return <SanityPage data={data} pageContext={pageContext} />
}

export default SanityPageTemplate
